import React from "react";
import { Link } from "react-scroll";

const ColorSwithcer = ({changeColorInstance}) => {
    
    const {color, changeColor}=changeColorInstance;



    return <Link
    className="btn btn-default"
    // to="section-contact"
    spy={true}
    smooth={true}
    duration={500}
    onClick={changeColor}
  >
    <span className={`fa fa-${color && color === 'dark' ? 'moon' : 'sun'}`}></span>
  </Link>
};
export default ColorSwithcer;