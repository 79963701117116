
const BackButton = ()=>{


    return <a
    className="btn btn-default"
    to="/"
    href="/"
    spy={true}
    smooth={true}
    duration={500}
    // onClick={()=>window.location.replace('/')}
  >
    <span className="fa fa-chevron-left"></span>
  </a>
};
export default BackButton;